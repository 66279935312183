body {
  margin: 0;
  font-family: "Arial Black", Gadget, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-background {
  height: 110%;
  width: 100%;
  /* background:linear-gradient(rgba(85, 212, 169, 0.5),transparent);
  background-color: #06183b; */
  background-image: url(./Images/globe.png);
  z-index: -1;
}

.large {
  font-size: 300%;
  font-weight: bold;
}

.is-transparent {
  background-color: rgba(255, 255, 255, .5) !important;
  font-family: 'Nunito', sans-serif;
}

.is-transparent-more {
  background-color: rgba(255, 255, 255, 0) !important;
}

.gradient {
  height: 35px;
  width: 90%;
  background-color: red; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, green, yellow, orange, red); /* Standard syntax (must be last) */
}